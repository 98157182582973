import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  icons,
  sortingTable,
  sortingTableNumbers,
} from "../../components/ShareComp";
import ReactTableShare from "../../components/ReactTableShare";
import User from "../../assets/images/user.jpg";
import CreateAppointment from "./CreateAppointment";
import Select from "react-select";
import moment from "moment";
import { UseFormValidations } from "../../validations/UseFormValidation";
const AppointmentList = (props) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [list, setList] = useState([]);
  const { data, handleChangeSearch } = UseFormValidations({});
  const columns = [
    {
      name: "Id",
      selector: (v) => v?.appointmentId,
      width: "5rem",
      sortFunction: (a, b) => sortingTableNumbers(a, b, "appointmentId"),
    },

    {
      name: "Name",
      selector: (v) => <div className="text-wrap">{v?.patientName}</div>,
      sortable: true,
      width: "9rem",
      sortFunction: (a, b) => sortingTable(a, b, "patientName"),
    },
    {
      name: "Email",
      selector: (v) => <div className="text-wrap">{v?.email}</div>,
      sortable: true,
      width: "12rem",
      sortFunction: (a, b) => sortingTable(a, b, "email"),
    },

    {
      name: "Hospital",
      selector: (v) => <div className="text-wrap">{v?.hospitalName}</div>,
      sortable: true,
      width: "10rem",
      sortFunction: (a, b) => sortingTable(a, b, "hospitalName"),
    },
    {
      name: "Date&Time",
      selector: (v) => (
        <div>
          <div className="">{v?.preferredDate}</div>
          <div
            style={{ fontSize: "12px", fontWeight: "500" }}
          >{`[${v?.preferredTime}]`}</div>
        </div>
      ),
      // sortable: true,
      width: "10rem",
      sortFunction: (a, b) => sortingTable(a, b, "preferredDate"),
    },
    {
      name: "Doctor",
      selector: (v) => (
        <div>
          <div>{v?.clinicianName}</div>
          <div
            style={{ fontSize: "12px", fontWeight: "500" }}
          >{`[${v?.specialityName}]`}</div>
        </div>
      ),

      sortable: true,
      width: "10rem",
      sortFunction: (a, b) => sortingTable(a, b, "clinicianName"),
    },
    {
      name: "Type",
      selector: (v) => v?.type,
      sortFunction: (a, b) => sortingTable(a, b, "type"),
      sortable: true,
      width: "9rem",
    },
    {
      name: "Status",
      selector: (v) => (
        <div>
          <div
            className={
              v.status == "Completed"
                ? "status_comp p-1 px-2 "
                : v.status == "Waitlisted"
                ? "status_wait p-1 px-2 te"
                : v.status == "Checkin" && "status_check p-1 px-3 te"
            }
          >
            {v?.status}
          </div>
        </div>
      ),
      sortFunction: (a, b) => sortingTable(a, b, "status"),
      sortable: true,
      
    },
   
  ];

  const handleChang = () => (e) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    setList(props?.list);
  }, [props?.list]);
  useEffect(() => {
    if (data?.hospitalId?.value) {
      let res = props?.list.filter(
        (v) => v.hospitalId == data?.hospitalId?.value
      );
      console?.log(res, "res");
      setList(res);
    } else {
      setList(props?.list);
    }
  }, [data?.hospitalId?.value]);

  const listsearch = () => {
    return (
      <div className=" col-md-12 d-flex flex-wrap gap-1">
        <div className="col-6">
          <input
            type="search"
            className="form-control search-control search-bg "
            value={search != "" ? search : ""}
            onChange={handleChang()}
            placeholder="Search..."
          />
        </div>
        <div className="col">
          <Select
            styles={{ zIndex: "1" }}
            isClearable={true}
            options={props?.hospital}
            placeholder={"Select Hospital"}
            className="text-start index"
            name="facility"
            onChange={handleChangeSearch("hospitalId")}
          />
        </div>
        
      </div>
    );
  };

  return (
    <div >
      <div className="d-flex flex-wrap py-1 col-md-12"  >
        <div className="col-md-12 px-2 heading_list ">Appointments</div>
        
      </div>
      <div className="py-2 ">
        <ReactTableShare
          dataTable={list || []}
          columns={columns || []}
          search1={listsearch}
          search={search}
        />
        {open && (
          <CreateAppointment show={open} onHide={() => setOpen(false)} />
        )}
      </div>
    </div>
  );
};

export default AppointmentList;
