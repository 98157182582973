import React, { Suspense, useEffect, useState } from "react";

import "./App.css";
import { BrowserRouter} from "react-router-dom";
import RoutingConfig from "./routing/RoutingConfig";
import {ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

// import { io } from "socket.io-client";
function App() {
  
//   const socket = io("http://localhost:5000");

// const [count, setCount] = useState(0);

// useEffect(() => {
//   socket.on("updateCount", (newCount) => {
//     setCount(newCount);
//   });
// console?.log(count,"count")
//   return () => {
//     socket.off("updateCount");
//   };
// }, []);
// const handleIncrement = () => {
//   socket.emit("increment"); // Send increment event to server
// };
  return (
   
    
    <Suspense fallback={()=><div>Loading...</div>}>
      {/* <button onClick={handleIncrement} >click{count}</button> */}
 <BrowserRouter>
    <ToastContainer />
   <RoutingConfig/>
  
   </BrowserRouter>
    </Suspense>
   
   
   
  );
}

export default App;
