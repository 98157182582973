import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import Navbar from '../components/NavBar'
import AppRouting from '../Modules/Appointment/Routing'
import Policy from '../components/Policy'
import PrivateRouting from './PrivateRouting'
import ChangePassword from '../components/ChangePassword'

const LandingPage =lazy(() => import("../components/LandingPage"))
const Patient_Dashboard =lazy(() => import("../components/Patient_Dashboard"))
const Labuserrouting =lazy(() => import("../Modules/labuser/Labuserrouting"))
const RoutingScan =lazy(() => import("../Modules/addscan/RoutingScan"))
const Routingupload =lazy(() => import("../Modules/upload/Routingupload"))
const RoutingOrg =lazy(() => import("../Modules/organization/RoutingOrg"))
const PHM =lazy(() => import("../components/PHM"))
const RoutingTransaction =lazy(() => import("../Modules/Transaction/RoutingTransaction"))
const PatientRouting =lazy(() => import("../Modules/Patient/PatientRouting"))
const UserRouting =lazy(() => import("../Modules/Users/UserRouting"))
const SpecialityRouting =lazy(() => import("../Modules/Speciality/SpecialityRouting"))
const DoctorRouting =lazy(() => import("../Modules/Doctor/DoctorRouting"))
const FacilityRouting =lazy(() => import("../Modules/Facility/FacilityRouting"))
const ScanRouting =lazy(() => import("../Modules/ScanPackage/ScanRouting"))
const SubscribRouting =lazy(() => import("../Modules/Subscribtion/SubscribRouting"))
const AdminDashbord =lazy(() => import("../Modules/Dashboard/AdminDashbord"))


const RoutingConfig = () => {
  return (

    <Routes>
        
        <Route path='/policy' element={<Policy/>}></Route>
       
        <Route path='/' element={<LandingPage/>}></Route>
       
        <Route  path='dashboard'  element={<PrivateRouting><Patient_Dashboard/></PrivateRouting>}></Route>
        <Route path='/forgot_password/:userId' element={<ChangePassword/>}></Route>
  <Route path="/xlcarez/*" element={<Navbar/>}>
<Route path='dashboard'element={<PrivateRouting><AdminDashbord/></PrivateRouting>}></Route>
<Route path='appointment/*'element={<PrivateRouting><AppRouting/></PrivateRouting>}></Route>
<Route path='subscribtion/*'element={<PrivateRouting><SubscribRouting/></PrivateRouting>}></Route>
<Route path='scanpackage/*'element={<PrivateRouting><ScanRouting/></PrivateRouting>}></Route>
<Route path='hospital/*'element={<PrivateRouting><FacilityRouting/></PrivateRouting>}></Route>
<Route path='speciality/*'element={<PrivateRouting><SpecialityRouting/></PrivateRouting>}></Route>
<Route path='doctor/*'element={<PrivateRouting><DoctorRouting/></PrivateRouting>}></Route>
<Route path='users/*'element={<PrivateRouting><UserRouting/></PrivateRouting>}></Route>
<Route path='patient/*'element={<PrivateRouting><PatientRouting/></PrivateRouting>}></Route>
<Route path='transaction/*'element={<PrivateRouting><RoutingTransaction/></PrivateRouting>}></Route>
<Route path='phm/all'element={<PrivateRouting><PHM/></PrivateRouting>}></Route>
<Route path='organization/*'element={<PrivateRouting><RoutingOrg/></PrivateRouting>}></Route>

<Route path='upload/*'element={<PrivateRouting><Routingupload/></PrivateRouting>}></Route>
<Route path='scan/*'element={<PrivateRouting><RoutingScan/></PrivateRouting>}></Route>
<Route path='labuser/*'element={<PrivateRouting><Labuserrouting/></PrivateRouting>}></Route>

        </Route>
        
    </Routes>
  )
}

export default RoutingConfig