import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { MdDashboard, MdLogout } from "react-icons/md";
import { GiSkills } from "react-icons/gi";
import {FaHandHoldingMedical,FaUser,FaHospitalSymbol, FaUserNurse,} from "react-icons/fa";
import { NavLink, Outlet } from "react-router-dom";
import logo from "../assets/images/applogo.png";
import { FaUserDoctor,} from "react-icons/fa6";
import { FiUsers } from "react-icons/fi";
import { icons, logOut } from "./ShareComp";
import { PiUserCirclePlusBold } from "react-icons/pi";
import { IoIosNotifications, IoMdCloudUpload } from "react-icons/io";
import { RiMessage2Fill } from "react-icons/ri";
import {  IoHomeOutline } from "react-icons/io5";
import { Icons } from "../services/Urls";


const Navbar = () => {
  const [state, setState] = useState(false);
  const [subMenu, setSubmenu] = useState(true);
  const link = [
    {
      linkName: "Dashboard",
      logo: (
        <IoHomeOutline
          size={30}
          color="white"
          className="  border-0 p-1"
          style={{ borderRadius: "10px", background: "#1C63A1" }}
        />
      ),
      url: "dashboard",
    },
{
      label: "Master",
      icon: (
        <MdDashboard
          size={30}
          color="white"
          className=" border-0 p-1"
          style={{ borderRadius: "10px", background: "#1C63A1" }}
        />
      ),
      child: [
        {
          linkName: "Users",
          logo: <FaUser size={25}  color="white"
          
          className="  p-1"
          style={{ borderRadius: "8px", background: "#1C63A1" }}
               />,
          url: "users/all",
        },
        
        {
          linkName: "Hospital",
          logo: (
            <FaHospitalSymbol
             size={25}  color="white"
             className="   p-1"
                  style={{ borderRadius: "8px", background: "#1C63A1" }}
            />
          ),
          url: "hospital/all",
        },
        {
          linkName: "Doctor",
          logo: (
            <FaUserDoctor
               size={25}  color="white"
               className="   p-1"
                    style={{ borderRadius: "8px", background: "#1C63A1" }}
            />
          ),
          url: "doctor/all",
        },
        {
          linkName: "Patient",
          logo: (
            <FiUsers
               size={25}  color="white"
               className="   p-1"
                    style={{ borderRadius: "8px", background: "#1C63A1" }}
            />
          ),
          url: "patient/all",
        },
        {
          linkName: "Lab-user",
          logo: (
            <FaUserNurse
               size={25}  color="white"
               className="   p-1"
                    style={{ borderRadius: "8px", background: "#1C63A1" }}
            />
          ),
          url: "labuser/all",
        },
        {
          linkName: "Speciality",
          logo: (
            <FaHandHoldingMedical
               size={25} 
                color="white"
          className="   p-1"
               style={{ borderRadius: "8px", background: "#1C63A1" }}
            />
          ),
          url: "speciality/all",
        },
        {
          linkName: "Upload",
          logo: (
            <IoMdCloudUpload
               size={25}  color="white"
               className="   p-1"
                    style={{ borderRadius: "8px", background: "#1C63A1" }}
            />
          ),
          url: "upload/all",
        },
        // {
        //   linkName: "Add Scan",
        //   logo: (
        //     <LuScanFace
        //        size={25}  color="white"
        //        className="   p-1"
        //             style={{ borderRadius: "8px", background: "#1C63A1" }}
        //     />
        //   ),
        //   url: "scan/all",
        // },
        
      ],
      logo: <GiSkills />,
      url: "",
    },
    {
      linkName: "Log Out",
      logo: (
        <MdLogout
          size={30}
          color="white"
          className="  p-1"
          style={{ borderRadius: "10px", background: "#1C63A1" }}
        />
      ),
      url: "/",
    },
  ];

  const filterNav = () => {
    let temp = [];
    let res =
      sessionStorage.getItem("roleId") == 2
        ? link?.filter((v) => v.linkName != "PHM")
        : link;
    res?.map((v) => {
      let te = v.child?.filter(
        (c) =>
          c.linkName == "Users" ||
          c.linkName == "Scan Packages" ||
          c.linkName == "Subscription" ||
          c?.linkName == "Organization"
      );
      let te2 = v?.child?.filter((c) => c.linkName != "PHM");

      let te3 = v.child?.filter(
        (c) =>
          c.linkName != "Users" &&
          c.linkName != "Hospital" &&
          c.linkName != "Scan Packages" &&
          c.linkName != "Subscription"&&
          c.linkName != "Upload"
      );

      v["child"] =
        sessionStorage.getItem("roleId") == 1
          ? te
          : sessionStorage.getItem("roleId") == 2
          ? te2
          : sessionStorage.getItem("roleId") == 3
          ? te3
          : [];
      temp.push(v);
    });

    return temp;
  };
  const roles = () => {
    switch (sessionStorage.getItem("roleId")) {
      case "1":
        return "Super Admin";
      case "2":
        return "Organization Admin";
      case "3":
        return "Hospital Admin";
      case "4":
        return "Doctor";
    }
  };
  return (
    <div className="d-flex">
      <div className="position-fixed" style={{ zIndex: "1" }}>
        <Sidebar
          transitionDuration={300}
          backgroundColor="#1C63A1"
          collapsed={state}
          style={{
            height: "100vh",
            position: "fixed",
            backgroundColor: "#1C63A1",
          }}
        >
          <div
            className={` col-md-12 d-flex align-items-center border-0 border-bottom  gap-2  mb-0 `}
            
            style={{ height: "74px" }}
          >
            <div className=""><img src={logo} className={`img-fluid sidebar nav_logo`} /></div>
            <div className="position-absolute  col-md-12 d-flex justify-content-end  align-items-center  " style={{left:"12px"}}>
              <span onClick={() => setState(!state)} className="bg-white ptr text-start" style={{borderRadius:"50px",height:"30px",width:"30px",paddingLeft:"3px"}}>{!state?Icons.close:Icons.open}</span>
            </div> 
             </div>
          {filterNav()?.map((v,i) => {
            return (
              <Menu
              key={i}
                className=""
                menuItemStyles={{
                  button: {
                    // the active class will be added automatically by react router
                    // so we can use it to style the active menu item
                    [`&.active`]: {
                      backgroundColor: "#668ebd",
                      color: "#ffff",
                      height: "40px",
                     
                    },
                  },
                }}
              >
                {v?.linkName && (
                  <MenuItem
                  
                    icon={v?.logo}
                    onClick={v?.linkName == "Log Out" && logOut}
                    component={
                      <NavLink  className={"text-white menu-item"} to={v?.url} />
                    }
                  >
                    {v?.linkName}
                  </MenuItem>
                )}
                {v?.child && (
                  <SubMenu
                    className="positon-absolute  menu-item1 text-white"
                    open={subMenu}
                    onClick={() => setSubmenu(!subMenu)}
                    icon={v?.icon}
                    title="Master"
                    label={v?.label}
                  >
                    {v?.child?.map((c,index) => {
                      return (
                        <MenuItem
                          style={{ zIndex: "1" }}
                          icon={c.logo}
                       
                          component={
                            <NavLink
                            key={index}
                              className={"nab_bg menu-item text-white"}
                              to={c?.url}
                            ></NavLink>
                          }
                        >
                          {c?.linkName}
                        </MenuItem>
                      );
                    })}
                  </SubMenu>
                )}
              </Menu>
            );
          })}
        </Sidebar>
      </div>

      <div
        className={state ? "sidebar " : "sidebar"}
        style={{
          width: "100%",
          marginLeft: state ? "5rem" : "15.5rem",
          transition: "ease-out",
         
        }}
      >
        
        <div
          className="d-flex flex-wrap border-bottom align-items-center px-3 "
          style={{ height: "74px",zIndex:"1"  }}
        >
          <div className="col-md-4"></div>
          <div className="col-md-4 d-flex justify-content-end gap-4 ">
           </div>
          <div
            className="col-md-4 d-flex flex-wrap gap-3 justify-content-end align-items-center "
           
          >
           
            <div>
              <RiMessage2Fill size={18} />
            </div>
            <div>
              <IoIosNotifications size={20} />
            </div>
            <div>
              <div style={{ fontSize: "14px", fontWeight: "600" }}>
                {sessionStorage.getItem("firstName") +
                  " " +
                  sessionStorage.getItem("lastName")}
              </div>
              <div
                style={{ fontSize: "13px", fontWeight: "400" }}
              >{`[${roles()}]`}</div>
            </div>
            <div>
              <PiUserCirclePlusBold size={25} />
            </div>
          </div>
        </div>
        
        <div className="px-2 py-2 bg-white" >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
