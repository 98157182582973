import { createSlice } from "@reduxjs/toolkit";

const Reducer = createSlice({
  name: "status",
  initialState: { isActive: false, message: "" },
  reducers: {
    setStatus: (state, action) => {
      state.isActive = action.payload.isActive;
      state.message = action.payload.message;
    },
  },
});
export const { setStatus } = Reducer.actions;
export default Reducer.reducer;